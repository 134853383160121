<template>
  <div> &nbsp; </div>
</template>

<script>
import { parseDomain, ParseResultType } from 'parse-domain'

export default {
  created() {
    const parseDomainResult = parseDomain(window.location.hostname)
    const currentPort = typeof window.location.port !== 'undefined' ? `:${window.location.port}` : null
    if (parseDomainResult.type === ParseResultType.Listed) {
      const { domain, topLevelDomains } = parseDomainResult
      const targetUrl = `https://app.${domain}.${topLevelDomains}${currentPort}/login`
      window.location = targetUrl
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import 'src/assets/scss/elements/login-page.scss';
</style>
